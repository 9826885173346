import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import PreviewCompatibleImage from "../components/PreviewCompatibleImage";

const FeatureList = ({ gridItems }) => (
  <div className="columns is-multiline">
    {gridItems.map((item, idx) => (
      <div key={`${idx}-feat-list`} className="column is-4">
        <section className="section">
          <div className="has-text-centered">
            <div
              style={{
                maxWidth: 240,
                width: "100%",
                height: 100,
                display: "inline-block",
                alignItems: "center",
              }}
            >
              <PreviewCompatibleImage
                imageInfo={{
                  image: item.image,
                  extension: item.image.extension,
                  publicURL: item.image.publicURL,
                  alt: item.title,
                  childImageSharp: item.image.childImageSharp,
                  style: {
                    maxHeight: "100%",
                  },
                }}
              />
            </div>
          </div>

          <div className="container" style={{ minHeight: 70 }}>
            <h3 className="title has-text-centered">{item.title}</h3>
          </div>

          <div className="container">
            {Array.isArray(item.list)
              ? item.list.map((listItem, liIdx) =>
                  listItem && listItem.path ? (
                    <Link
                      to={listItem.path}
                      key={`${idx}-${liIdx}-flist-listitem`}
                      className={"button is-white is-fullwidth"}
                    >
                      <p className={"has-text-grey-dark"}>{listItem.text}</p>
                    </Link>
                  ) : listItem.link ? (
                    <a
                      href={listItem.link}
                      key={`${idx}-${liIdx}-flist-listitem`}
                      className={"button is-white is-fullwidth"}
                      target={"_blank"}
                      rel={"noopener noreferrer"}
                    >
                      <p className={"has-text-grey-dark"}>{listItem.text}</p>
                    </a>
                  ) : (
                    <div
                      key={`${idx}-${liIdx}-flist-listitem`}
                      className={"button is-white is-fullwidth"}
                    >
                      <p className={"has-text-grey-dark"}>{listItem.text}</p>
                    </div>
                  )
                )
              : null}
          </div>
        </section>
      </div>
    ))}
  </div>
);

FeatureList.propTypes = {
  gridItems: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.oneOfType([PropTypes.object, PropTypes.string])
        .isRequired,
      title: PropTypes.string.isRequired,
      list: PropTypes.arrayOf(
        PropTypes.shape({
          text: PropTypes.string.isRequired,
          path: PropTypes.string,
          link: PropTypes.string,
        })
      ).isRequired,
    })
  ),
};

export default FeatureList;
