import React from "react";
import PropTypes from "prop-types";
import { graphql, Link } from "gatsby";

import Layout from "../components/Layout";
import Features from "../components/FeatureLists";
import BlogRoll from "../components/BlogRoll";
import BlogRollProperty from "../components/BlogRollProperty";

export const IndexPageTemplate = ({
  banner,
  mainpitch,
  secondpitch,
  intro,
  footbanner,
}) => (
  <div>
    <div
      className="full-width-image mt-1"
      style={{
        backgroundImage: `url(${
          !!banner.image.childImageSharp
            ? banner.image.childImageSharp.fluid.src
            : banner.image
        })`,
        backgroundPosition: `top left`,
        backgroundAttachment: `fixed`,
        height: "100vh",
        minHeight: 300,
        maxHeight: 800,
      }}
    >
      <div className="section">
        <div
          style={{
            display: "flex",
            height: "150px",
            lineHeight: "1",
            justifyContent: "space-around",
            alignItems: "left",
            flexDirection: "column",
          }}
        >
          <h1
            className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
            style={{
              color: "white",
              padding: "0.25em",
            }}
          >
            {banner.title}
          </h1>
          <h3
            className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
            style={{
              color: "white",
              padding: "0.25em",
            }}
          >
            {banner.subtitle}
          </h3>
          <div className="has-text-centered pt-6">
            <Link
              to="/apply-now"
              className="button is-success is-rounded is-large has-text-weight-bold"
            >
              Start Here
            </Link>
          </div>
        </div>
      </div>
    </div>
    <section className="section section--gradient">
      <div className="container">
        <div className="section">
          <div className="columns">
            <div className="column is-10 is-offset-1">
              <div className="content">
                <div className="content">
                  <div className="container">
                    <h1 className="title has-text-centered">
                      {mainpitch.title}
                    </h1>
                  </div>
                  <div className="container">
                    <p className="is-size-6 has-text-centered has-text-grey-dark">
                      {mainpitch.subtitle}
                    </p>
                  </div>
                </div>
                <Features gridItems={intro.blurbs} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        id="secondpitch"
        style={{
          backgroundImage: `url(${
            !!secondpitch.image.childImageSharp
              ? secondpitch.image.childImageSharp.fluid.src
              : secondpitch.image
          })`,
          backgroundPosition: "center",
        }}
      >
        <div className="py-6">
          <div className="container">
            <div className="">
              <div className="container has-text-centered">
                <h1 className={"title is-size-2 has-text-white"}>
                  {secondpitch.title}
                </h1>
                <hr className={"has-background-dark"} />
              </div>
              <div className="columns is-centered">
                <div className="column is-half ">
                  <div
                    className={"container"}
                    style={{
                      padding: "2rem",
                      borderRadius: 4,
                      backgroundColor: "rgba(0, 0, 0, 0.3)",
                    }}
                  >
                    <p className={"has-text-white"}>
                      {secondpitch.description}
                    </p>
                  </div>
                  <div className="container has-text-centered mt-2">
                    <h6 className="title is-size-6 has-text-white">
                      {secondpitch.name}
                    </h6>
                    <h6 className="title is-size-6 has-text-grey-light mt-1">
                      {secondpitch.type}
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div className="section">
        <div className="container">
          <div className="has-text-centered">
            <h3 className="has-text-weight-semibold is-size-2">
              Latest stories
            </h3>
            <hr className="mt-2" />
          </div>
          <div className="my-2">
            <BlogRoll limit={4} />
          </div>
          <div className="has-text-centered">
            <h3 className="has-text-weight-semibold is-size-2">Properties</h3>
            <hr className="mt-2" />
          </div>
          <div className="my-2">
            <BlogRollProperty limit={4} />
          </div>
          <div className="py-2 has-text-centered">
            <Link className="button is-link" to="/blog">
              Read more
            </Link>
          </div>
        </div>
      </div>
    </section>

    <section>
      <div
        id="index-experts-footer"
        className="full-width-image mt-1"
        style={{
          backgroundImage: `url(${
            !!footbanner.image.childImageSharp
              ? footbanner.image.childImageSharp.fluid.src
              : footbanner.image
          })`,
          backgroundPosition: `center`,
          height: "100vh",
          minHeight: 200,
          maxHeight: 500,
        }}
      >
        <div className="section">
          <div
            style={{
              display: "flex",
              height: "150px",
              lineHeight: "1",
              justifyContent: "space-around",
              alignItems: "left",
              flexDirection: "column",
            }}
          >
            <h1
              className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
              style={{
                color: "white",
                padding: "0.25em",
              }}
            >
              {footbanner.title}
            </h1>
            <h3
              className="has-text-weight-bold is-size-5-mobile is-size-5-tablet is-size-4-widescreen"
              style={{
                color: "white",
                padding: "0.25em",
              }}
            >
              {footbanner.subtitle}
            </h3>
          </div>
        </div>
      </div>
    </section>
  </div>
);

IndexPageTemplate.propTypes = {
  banner: PropTypes.object,
  mainpitch: PropTypes.object,
  secondpitch: PropTypes.object,
  intro: PropTypes.shape({
    blurbs: PropTypes.array,
  }),
  footbanner: PropTypes.object,
};

const IndexPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;

  return (
    <Layout>
      <IndexPageTemplate
        banner={frontmatter.banner}
        mainpitch={frontmatter.mainpitch}
        secondpitch={frontmatter.secondpitch}
        intro={frontmatter.intro}
        footbanner={frontmatter.footbanner}
      />
    </Layout>
  );
};

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage;

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        banner {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        mainpitch {
          title
          subtitle
        }
        intro {
          blurbs {
            image {
              childImageSharp {
                fluid(maxWidth: 240, quality: 64) {
                  ...GatsbyImageSharpFluid
                }
              }
              extension
              publicURL
            }
            title
            list {
              text
              path
            }
          }
        }
        secondpitch {
          title
          description
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          name
          type
        }
        footbanner {
          title
          subtitle
          image {
            childImageSharp {
              fluid(maxWidth: 2048, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  }
`;
